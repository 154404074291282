exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-web-js": () => import("./../../../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */),
  "component---src-templates-electorate-page-js": () => import("./../../../src/templates/electorate-page.js" /* webpackChunkName: "component---src-templates-electorate-page-js" */),
  "component---src-templates-hub-page-js": () => import("./../../../src/templates/hub-page.js" /* webpackChunkName: "component---src-templates-hub-page-js" */),
  "component---src-templates-senate-js": () => import("./../../../src/templates/senate.js" /* webpackChunkName: "component---src-templates-senate-js" */),
  "component---src-templates-web-js": () => import("./../../../src/templates/web.js" /* webpackChunkName: "component---src-templates-web-js" */)
}

